
import '../../assets/iconfont.css';
import { getArticleList } from '@/api';
import {useRouter} from 'vue-router';
import { ref } from 'vue';
import { useStore } from 'vuex';
export default {
  setup(){
    const router = useRouter();
    const pageCount = ref();
    const page = ref(1);
    const pageSize = 10;
    const articleList = ref([]);
    const store = useStore();
    store.dispatch('isLoading');
    getArticleList({"page": page.value,"pageSize": pageSize}).then((res: any) => {
      pageCount.value = res.data.pages;
      articleList.value = res.data.list;
      store.dispatch('notLoading');
    });

    const loadArticle = () => {
      store.dispatch('isLoading');
      getArticleList({"page": page.value,"pageSize": pageSize}).then((res: any) => {
        articleList.value = res.data.list;
      }).then(() => {
        scrollTo(0,0);
        store.dispatch('notLoading');
      });
    }

    const readArticle = (id) =>{ 
      store.dispatch('closeSidebar');
      router.push({
        path: '/p/' + id, 
      });
    }
  

    return {
      loadArticle,
      articleList,
      pageCount,
      page,
      readArticle,
    }
  }

}
